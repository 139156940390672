import { RecursiveKeyValuePair } from 'tailwindcss/types/config';

export class AppColors {
  readonly black = '#212121';
  readonly white = '#ffffff';
  readonly gray = {
    dark: '#818181',
    lines: '#d4d4d4',
    light: '#f9f9f9',
  };
  readonly alert = '#d60605';
  readonly success = '#277d57';
}

let singleton: AppColors;
export class AppColorsResolver extends AppColors {
  static resolveColors() {
    if (!singleton) {
      singleton = new AppColors();
    }
    return singleton as unknown as RecursiveKeyValuePair<string, string>;
  }
}
