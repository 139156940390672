@defer (when true) {
  <div class="h-0"><app-sprite-svg /></div>
}
<div
  class="h-0"
  #dialogContainer>
</div>

@if (loading()) {
  <app-loading-page />
}

<router-outlet></router-outlet>
