import { HttpParams } from '@angular/common/http';
import { CustomURLEncoder } from '@app/services/custom-url-encoder/custom-url-encoder';
import { Utm } from '@app/interfaces';

export interface IApi {
  buildBaseUrl(input: string): string;
}

export class Api {
  protected params(object: object): HttpParams {
    let parameters = new HttpParams({
      encoder: new CustomURLEncoder(),
    });
    Object.entries(object).forEach(([key, value]) => {
      if (String(value) !== 'undefined') {
        parameters = parameters.append(key, String(value));
      }
    });
    return parameters;
  }

  protected setUtmTagsToHttpParams(utm?: Utm) {
    const queryParams = new HttpParams();

    if (utm?.utm_source) {
      queryParams.set('utm_source', utm.utm_source);
    }
    if (utm?.utm_medium) {
      queryParams.set('utm_medium', utm.utm_medium);
    }
    if (utm?.utm_campaign) {
      queryParams.set('utm_campaign', utm.utm_campaign);
    }
    if (utm?.utm_term) {
      queryParams.set('utm_term', utm.utm_term);
    }
    if (utm?.utm_content) {
      queryParams.set('utm_content', utm.utm_content);
    }

    return queryParams;
  }
}
