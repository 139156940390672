import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class ExternalUrlResolverService implements Resolve<string | null> {
  constructor() {}

  resolve(route: ActivatedRouteSnapshot) {
    const url = route.paramMap.get('externalUrl');
    if (url) {
      window.open(url, '_self');
    }
    return url;
  }
}
