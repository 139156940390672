import { Injectable } from '@angular/core';

export interface Review {
  title: string;
  nickname: string;
  comment: string;
  rating: number;
}

@Injectable({
  providedIn: 'root',
})
export class DictionaryService {
  constructor() {}

  reviews: Review[] = [
    {
      title: 'Amazing',
      nickname: 'by thewhitefern',
      rating: 4.6,
      comment:
        'I’ve taken more pilates/yoga classes than I could ever count. I’ve been in gymnastics/cheer and countless other sports involving intense stretching and this app is by far the best stretching I’ve ever done. 10000/10 would recommend.',
    },
    {
      title: 'Love it',
      nickname: 'by ari',
      rating: 4.8,
      comment:
        'This app helps me stay on top of some pretty necessary self-care. All of the instructors are clear and precise and the classes are really geared to my needs. It seems like a lot, but it is totally worth the monthly subscription if you use it like I do. If you’re looking for a lifestyle change, this app is a good place to start.',
    },
    {
      title: 'Great app ',
      nickname: 'by Peggi234323432',
      rating: 4.9,
      comment:
        'I was looking for an easy way to help my body stay flexible and relaxed and this app helped me a lot. I am so addicted to the classes. they are great, doable, and easy. You will see the change in your body and mood that will never let yourself to let go of this app.',
    },
    {
      title: 'Recommend',
      nickname: 'by meow033',
      rating: 4.7,
      comment:
        'Most certainly the best stretching application I’ve ever tried. Well-structured classes, amazing timely support (via Instagram, email) and always keeping you engaged.',
    },
    {
      title: 'Excellent',
      nickname: 'by Theresa McG',
      rating: 4.5,
      comment:
        'As a former ballet dancer and teacher, these classes have been excellent in getting every part of my body stretched. After a couple of serious injuries and five kids, my body has really tightened up, and the STRETCHIT app classes have been a godsend. Highly, highly recommend for every body.',
    },
  ];

  menReviews: Review[] = [
    {
      title: 'Feel good',
      nickname: 'by Kay $',
      rating: 4.9,
      comment:
        'After completing a 30 day bendy back challenge I can honestly say that I see some real improvements in my shoulder mobility and back flexibility. This app has given me hope that it’s not all downhill from here, I just needed to do the right exercises. If you are finding that you stuck, i highly recommend doing one of the 30 day challenges, track your progress and see if it works for you!',
    },
    {
      title: 'Wonderful',
      nickname: 'by Tom Mickleburgh',
      rating: 4.7,
      comment:
        'STRETCHIT - wonderful app, easy to use with varied skill levels for all. My mobility and flexibility are improving with this app. I’m really pleased I found it’s helping my body no end.',
    },
    {
      title: 'Recommend',
      nickname: 'by Dave the ferg',
      rating: 4.5,
      comment:
        'I had a crook back and wanted to strengthen it gently. Now I have done 2 days total of stretching and training to do the splits. Recommend highly.',
    },
    {
      title: 'Great',
      nickname: 'by Leon Hendren',
      rating: 4.8,
      comment:
        'Great programs. I like it’s no extra chatter, straight into the class, great camera angles.',
    },
  ];
}
