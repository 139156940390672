import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ProfileParameters, User } from './models/User';
import { Utm } from '@app/interfaces';

export const UserActions = createActionGroup({
  source: 'User',
  events: {
    'Reset User': emptyProps(),
    'Load User': emptyProps(),
    'Load User Success': props<{ user: User }>(),
    'Load User Failure': emptyProps(),
    'Update User': props<{ props: ProfileParameters }>(),
    'Update User Success': props<{ user: User }>(),
    'Update User Failure': emptyProps(),
    'Register Draft User': props<{
      email: string;
      userProps: ProfileParameters;
      challenge_id?: number;
      onboarding?: string;
      utm?: Utm;
    }>(),
    'Register Draft User Success': props<{ user: User }>(),
    'Register Draft User Failure': props<{ emailExists: boolean }>(),
  },
});
