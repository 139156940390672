import { Routes } from '@angular/router';
import { ExternalUrlResolverService } from './core/resolvers/external-url-resolver/external-url-resolver.service';
import { LoadingComponent } from '@app/components/ui/loading/loading.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/quiz67.1', // default quiz
    pathMatch: 'full',
  },
  {
    path: 'external',
    resolve: {
      url: ExternalUrlResolverService,
    },
    component: LoadingComponent,
  },
  {
    path: 'editor',
    loadComponent: () => import('./pages/editor/editor.component').then(m => m.EditorComponent),
  },
  {
    path: ':quizName',
    loadComponent: () => import('./pages/quiz/quiz.component').then(m => m.QuizComponent),
  },
];
