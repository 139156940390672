import { Injectable } from '@angular/core';

export interface IStorage {
  getItem(name: StorageKey): string;

  setItem(key: StorageKey, value: string): void;

  removeItem(key: StorageKey): void;

  popItem(name: StorageKey): string;

  clear(): void;
}

export enum StorageKey {
  //Tokens
  access_token = 'access_token',
  // UTM
  utm_source = 'utm_source',
  utm_medium = 'utm_medium',
  utm_campaign = 'utm_campaign',
  utm_term = 'utm_term',
  utm_content = 'utm_content',
  // From
  from = 'from',
  // Locale
  lang = 'current_lang',
  // Analitics
  _fbc = '_fbc',
  _fbp = '_fbp',
  _ga = '_ga',
  _gid = '_gid',
}

@Injectable({
  providedIn: 'root',
})
export class StorageService implements IStorage {
  getItem(name: StorageKey): string {
    return (name && localStorage.getItem(name)) ?? '';
  }

  clear(): void {
    localStorage.clear();
  }

  setItem(key: StorageKey, value: string): void {
    localStorage.setItem(key, value);
  }

  removeItem(key: StorageKey): void {
    localStorage.removeItem(key);
  }

  popItem(name: StorageKey): string {
    const item = localStorage.getItem(name);
    if (item) {
      localStorage.removeItem(name);
      return item;
    }

    return '';
  }
}
