import { Component, inject, OnInit, signal, ViewChild, ViewContainerRef } from '@angular/core';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { SpriteSvgComponent } from '@app/components/sprite-svg/sprite-svg.component';
import { DialogService } from '@app/components/ui/dialog/services/dialog.service';
import { Store } from '@ngrx/store';
import { UserActions } from '@app/store/user/actions';
import { filter } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UtmTagsService } from '@app/services/utm-tags-service/utm-tags-service';
import { LoadingPageComponent } from '@app/components/loading-page/loading-page.component';
import { selectQueryParams } from '@app/store/router/router.selectors';
import { StorageKey, StorageService } from '@app/services/storage/storage.service';
import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { LocaleService } from '@app/services/locale/locale.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, SpriteSvgComponent, LoadingPageComponent],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  readonly dialogService = inject(DialogService);
  readonly store = inject(Store);
  readonly router = inject(Router);
  readonly storage = inject(StorageService);
  readonly utmTagsService = inject(UtmTagsService);
  readonly analytics = inject(AnalyticsService);
  readonly localeService = inject(LocaleService);

  readonly loading = signal(true);
  readonly queryParams = this.store.selectSignal(selectQueryParams);

  @ViewChild('dialogContainer', { read: ViewContainerRef, static: true })
  dialogContainerRef?: ViewContainerRef;

  ngOnInit(): void {
    this.store.dispatch(UserActions.loadUser());
    if (this.dialogContainerRef) {
      this.dialogService.registerViewContainer(this.dialogContainerRef);
    }
    this.utmTagsService.initUtmTagsConversion();

    this.localeService.initLocale();

    this.router.events
      .pipe(
        filter((e: Event) => this.navigationStart(e) || this.navigationOther(e)),
        untilDestroyed(this)
      )
      .subscribe((event: Event) => {
        if (this.navigationStart(event)) {
          this.loading.set(true);
        }

        if (this.navigationEnd(event)) {
          this.utmTagsService.initUtmTagsConversion();
          this.analytics.pageView(event);
        }

        if (this.navigationOther(event)) {
          this.setFromParam();
          this.loading.set(false);
        }
      });
  }

  private navigationStart(e: Event): boolean {
    return e instanceof NavigationStart;
  }

  private navigationEnd(e: Event): boolean {
    return e instanceof NavigationEnd;
  }

  private navigationOther(e: Event): boolean {
    return (
      e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError
    );
  }

  private setFromParam(): void {
    const from = this.queryParams()['from'];
    if (from) {
      this.storage.setItem(StorageKey.from, from);
    }
  }
}
