import { Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StorageKey, StorageService } from '../storage/storage.service';
import { Utm } from '@app/interfaces';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class UtmTagsService {
  constructor(
    private storageContext: StorageService,
    private route: ActivatedRoute
  ) {}

  getUtmTags(): Utm {
    const utm_source = this.storageContext.getItem(StorageKey.utm_source) ?? '';
    const utm_medium = this.storageContext.getItem(StorageKey.utm_medium) ?? '';
    const utm_campaign = this.storageContext.getItem(StorageKey.utm_campaign) ?? '';
    const utm_term = this.storageContext.getItem(StorageKey.utm_term) ?? '';
    const utm_content = this.storageContext.getItem(StorageKey.utm_content) ?? '';

    return {
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content,
    };
  }

  initUtmTagsConversion() {
    if (this.hasUtmTagsInRoute()) {
      this.route.queryParamMap
        .pipe(
          untilDestroyed(this),
          take(1),
          map(queryParamsMap => {
            const utmTags = this.getUtmTagsFromRoute(queryParamsMap);

            this.setUtmTagsToStorage(utmTags);
          })
        )
        .subscribe();
    }
  }

  private hasUtmTagsInRoute(): boolean {
    return window.location.href.includes('utm');
  }

  private setUtmTagsToStorage(utmTags: Utm) {
    if (utmTags.utm_source) {
      this.storageContext.setItem(StorageKey.utm_source, utmTags.utm_source);
    }
    if (utmTags.utm_medium) {
      this.storageContext.setItem(StorageKey.utm_medium, utmTags.utm_medium);
    }
    if (utmTags.utm_campaign) {
      this.storageContext.setItem(StorageKey.utm_campaign, utmTags.utm_campaign);
    }
    if (utmTags.utm_term) {
      this.storageContext.setItem(StorageKey.utm_term, utmTags.utm_term);
    }
    if (utmTags.utm_content) {
      this.storageContext.setItem(StorageKey.utm_content, utmTags.utm_content);
    }
  }

  private getUtmTagsFromRoute(queryParamMap: ParamMap): Utm {
    const utm_source = queryParamMap.get(StorageKey.utm_source) ?? '';
    const utm_medium = queryParamMap.get(StorageKey.utm_medium) ?? '';
    const utm_campaign = queryParamMap.get(StorageKey.utm_campaign) ?? '';
    const utm_term = queryParamMap.get(StorageKey.utm_term) ?? '';
    const utm_content = queryParamMap.get(StorageKey.utm_content) ?? '';

    return {
      utm_source,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content,
    };
  }
}
