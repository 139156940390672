import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { DictionaryService } from '@app/services/dictionary/dictionary.service';
import { QuizActions } from './actions';
import { filter, map, of, switchMap } from 'rxjs';
import {
  distinctUntilChangedDeep,
  isNotNullish,
  isNotNullishValues,
  isNullish,
  waitForTrue,
} from '@app/utils';
import { Difficulty, quizConfigMap } from '@app/dataset';
import { calcComplexity, getNextStep, getQuestionType, getRoutesList } from './helpers';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { selectQueryParams } from '../router/router.selectors';
import { AnalyticsService } from '@app/services/analytics/analytics.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { QuizFeature } from '@app/store/quiz/reducer';
import {
  dependenciesMap,
  isDependedQuestionType,
  isDurationSource,
  isTrainerSource,
} from '@app/store/quiz/models/QuizQuestion';
import { concatLatestFrom } from '@ngrx/operators';
import { LessonGroupProgramsApiService } from '@app/api';
import { QuizAnswer } from '@app/store/quiz/models/QuizAnswers';

export const loadQuiz$ = createEffect(
  (actions$ = inject(Actions), dictionary = inject(DictionaryService)) => {
    return actions$.pipe(
      ofType(QuizActions.loadQuiz),
      switchMap(({ name }) => {
        const resolver = quizConfigMap[name];
        if (isNullish(resolver)) {
          return of(QuizActions.loadQuizFailure());
        }

        return fromPromise(resolver()).pipe(
          switchMap(config => {
            const reviewsKey = config.params?.reviewsKey ?? 'reviews';
            const reviews = dictionary[reviewsKey];

            const routes = getRoutesList(config);

            return of(QuizActions.loadQuizSuccess({ config, reviews, routes, name }));
          })
        );
      })
    );
  },
  { functional: true }
);

export const getUserProps$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      switchMap(() =>
        store.select(selectQueryParams).pipe(
          switchMap(params =>
            of(
              QuizActions.setUserProps({
                userProps: {
                  stage: params['stage'] as string,
                  birthday: params['birthday'] as string,
                  weight: params['weight'] as string,
                  growth: params['growth'] as string,
                  weightLb: params['weightLb'] as string,
                  growthFt: params['growthFt'] as string,
                  growthInch: params['growthInch'] as string,
                  challengeId: params['challengeId'] as string,
                  onboardId: params['onboardId'] as string,
                  loading: params['loading'] !== 'false',
                  gender: params['gender'] as string,
                },
              })
            )
          )
        )
      )
    );
  },
  { functional: true }
);

export const stepCompleted$ = createEffect(
  (actions$ = inject(Actions), analytics = inject(AnalyticsService)) => {
    return actions$.pipe(
      ofType(QuizActions.nextStep),
      switchMap(({ answer }) => {
        let value = answer.value || 'no value';
        if (typeof value === 'object') {
          value = JSON.stringify(value);
        }
        return fromPromise(analytics.quizStepCompleted(answer.stepId, value.toString()));
      })
    );
  },
  { functional: true, dispatch: false }
);

export const loadNextStep$ = createEffect(
  (actions$ = inject(Actions), store = inject(Store)) => {
    return actions$.pipe(
      ofType(QuizActions.nextStep),
      concatLatestFrom(() => store.select(QuizFeature.selectConfig)),
      switchMap(([{ answer }, config]) => {
        const nextStep = getNextStep(config, answer);
        if (!nextStep) {
          return of(QuizActions.nextStepLoaded({}));
        }

        // Check question source dependencies
        const nextStepQuestionType = getQuestionType(nextStep);
        if (isNotNullish(nextStepQuestionType) && isDependedQuestionType(nextStepQuestionType)) {
          const sourceKey = dependenciesMap[nextStepQuestionType];
          return waitForTrue(
            store
              .select(QuizFeature.selectOptionSourcesLoading)
              .pipe(map(state => !state[sourceKey]))
          ).pipe(
            concatLatestFrom(() => store.select(QuizFeature.selectOptionSources)),
            switchMap(([, sources]) => {
              const source = sources[sourceKey];

              // Skip step if no source or no choices
              const answer: QuizAnswer = { stepId: nextStep.id, value: [] };
              if (isNullish(source)) {
                return of(QuizActions.nextStep({ answer }));
              }
              if (source.length <= 1) {
                if (source.length === 1 && isTrainerSource(source)) {
                  answer.value = source[0].map(({ id }) => id);
                }
                if (source.length === 1 && isDurationSource(source)) {
                  answer.value = source[0];
                }
                return of(QuizActions.nextStep({ answer }));
              }

              return of(QuizActions.nextStepLoaded({ nextStep }));
            })
          );
        }

        return of(QuizActions.nextStepLoaded({ nextStep }));
      })
    );
  },
  { functional: true }
);

export const defineComplexity$ = createEffect(
  (store = inject(Store)) =>
    store.select(QuizFeature.selectProgramParams).pipe(
      map(({ experienceLevel, flexibilityLevel }) => ({ experienceLevel, flexibilityLevel })),
      distinctUntilChangedDeep(),
      filter(isNotNullishValues),
      switchMap(({ experienceLevel, flexibilityLevel }) =>
        of(
          QuizActions.setProgramParams({
            programParams: { complexity: calcComplexity(experienceLevel, flexibilityLevel) },
          })
        )
      )
    ),
  { functional: true }
);

export const loadDurationFiltersInit$ = createEffect(
  (store = inject(Store)) =>
    store.select(QuizFeature.selectProgramParams).pipe(
      map(({ complexity, challenge_id }) => ({ complexity, challenge_id })),
      distinctUntilChangedDeep(),
      filter(isNotNullishValues),
      switchMap(params => of(QuizActions.loadOptionsSource({ source: 'durations', params })))
    ),
  { functional: true }
);

export const loadDurationFilters$ = createEffect(
  (actions$ = inject(Actions), api = inject(LessonGroupProgramsApiService)) => {
    return actions$.pipe(
      ofType(QuizActions.loadOptionsSource),
      filter(({ source }) => source === 'durations'),
      map(({ params }) => ({ complexity: params.complexity, challenge_id: params.challenge_id })),
      filter(isNotNullishValues),
      switchMap(({ complexity, challenge_id }) => {
        if (isNullish(complexity) || isNullish(challenge_id))
          return of(QuizActions.loadOptionsSourceFailure({ source: 'durations' }));

        return api.retrieveFilters({ complexity, challengeProgramsId: challenge_id }).pipe(
          switchMap(durations => {
            if (durations.length === 0 && complexity !== Difficulty.BEGINNER) {
              return of(
                QuizActions.setProgramParams({ programParams: { complexity: complexity - 1 } })
              );
            }
            if (durations.length === 0) {
              return of(QuizActions.loadOptionsSourceFailure({ source: 'durations' }));
            }
            return of(
              QuizActions.loadOptionsSourceSuccess({ source: 'durations', value: durations })
            );
          })
        );
      })
    );
  },
  { functional: true }
);

export const loadTrainerFiltersInit$ = createEffect(
  (store = inject(Store)) =>
    store.select(QuizFeature.selectProgramParams).pipe(
      map(({ challenge_id, complexity, durations }) => ({ challenge_id, complexity, durations })),
      distinctUntilChangedDeep(),
      filter(isNotNullishValues),
      switchMap(params => of(QuizActions.loadOptionsSource({ source: 'trainers', params })))
    ),
  { functional: true }
);

export const loadTrainerFilters$ = createEffect(
  (actions$ = inject(Actions), api = inject(LessonGroupProgramsApiService)) => {
    return actions$.pipe(
      ofType(QuizActions.loadOptionsSource),
      filter(({ source }) => source === 'trainers'),
      map(({ params }) => params),
      filter(isNotNullishValues),
      switchMap(({ challenge_id, complexity, durations }) => {
        if (isNullish(challenge_id) || isNullish(complexity) || isNullish(durations))
          return of(QuizActions.loadOptionsSourceFailure({ source: 'trainers' }));

        return api
          .retrieveTrainerFilters({
            challengeProgramsId: challenge_id,
            complexity,
            duration: durations,
          })
          .pipe(
            switchMap(trainers => {
              if (trainers.length === 0) {
                return of(QuizActions.loadOptionsSourceFailure({ source: 'trainers' }));
              }
              return of(
                QuizActions.loadOptionsSourceSuccess({ source: 'trainers', value: trainers })
              );
            })
          );
      })
    );
  },
  { functional: true }
);
