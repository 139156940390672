import { inject, Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { version } from '../../../../environments/version';
import dayjs from 'dayjs';
import { StorageKey, StorageService } from '../storage/storage.service';
import { Store } from '@ngrx/store';
import { UserActions } from '@app/store/user/actions';

type Headers = { [key: string]: string };

@Injectable({ providedIn: 'root' })
export class AccessTokenInterceptorService implements HttpInterceptor {
  readonly storageContext = inject(StorageService);
  readonly store = inject(Store);
  constructor() {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const headers = this.setHeaders(req);

    req = req.clone({
      headers: new HttpHeaders(headers),
    });

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse): Observable<HttpEvent<unknown>> => {
        if (err.error) {
          const error = err.error;

          if (error.message) {
            return AccessTokenInterceptorService.throw(error.message, String(err.status));
          }

          if (error.error_description) {
            return this.throwGrantError(error, err.status);
          }

          if (error.error) {
            return AccessTokenInterceptorService.throw(error.error);
          }

          if (error.form) {
            return this.throwFormError(error);
          }
        }

        return AccessTokenInterceptorService.throw('Connection error. Please try again later.');
      })
    );
  }

  static throw(message: string, code?: string): Observable<never> {
    const error = new Error(message);
    error.name = code || 'BackendError';
    return throwError(error);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private throwFormError(error: any): Observable<never> {
    return AccessTokenInterceptorService.throw(
      Object.keys(error.form.errors)
        .filter(key => !!key)
        .map(key => `* ${error.form.errors[key][0]} (${key})`)
        .join('\n'),
      'FormError'
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private throwGrantError(err: any, status: number): Observable<never> {
    if (err.error === 'invalid_grant' && status === 401) {
      this.clearUserData();
    }
    return AccessTokenInterceptorService.throw(err.error_description, String(status));
  }

  private clearUserData(): void {
    sessionStorage.clear();
    this.storageContext.clear();
    this.store.dispatch(UserActions.resetUser());
  }

  private setHeaders(req: HttpRequest<unknown>): Headers {
    console.log(req);
    const headers: Headers = {};
    const accessToken = this.storageContext.getItem(StorageKey.access_token);

    const attributes = {
      _fbc: this.storageContext.getItem(StorageKey._fbc),
      _fbp: this.storageContext.getItem(StorageKey._fbp),
      _ga: this.storageContext.getItem(StorageKey._ga),
      _gid: this.storageContext.getItem(StorageKey._gid),
    };

    headers['x-app-version'] = `web:${version}`;
    headers['x-attribute'] = JSON.stringify(attributes);

    const tz = dayjs.tz.guess();
    if (tz) {
      headers['x-time-zone'] = tz;
    }

    if (accessToken) {
      headers['x-token'] = `ApiKey="${accessToken.replace(/\s/g, '+')}"`;
      headers['X-Frame-Options'] = 'SAMEORIGIN';

      // TODO: set google analytics headers
      // if (this.googleAnalyticsService.getClientId()) {
      //   headers['x-ga-client-id'] = this.googleAnalyticsService.getClientId() ?? '';
      // }
      //
      // if (this.googleAnalyticsService.getSessionGtagId()) {
      //   headers['ga-session'] = this.googleAnalyticsService.getSessionGtagId() ?? '';
      // }
    }
    return headers;
  }
}
