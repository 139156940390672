import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Api, IApi } from '../api';
import { Duration, Trainer } from '@app/dataset';
import { PROGRAM_BASE_URL } from '@app/tokens';

export interface FiltersParams {
  complexity: number;
  challengeProgramsId: number;
}

export interface TrainerFilterParams {
  complexity: number;
  challengeProgramsId: number;
  duration: Duration[];
}

@Injectable({ providedIn: 'root' })
export class LessonGroupProgramsApiService extends Api implements IApi {
  private readonly http = inject(HttpClient);
  private baseUrl = inject(PROGRAM_BASE_URL);
  constructor() {
    super();
  }

  buildBaseUrl(input: string): string {
    return this.baseUrl + '/' + input;
  }

  retrieveFilters(params: FiltersParams): Observable<Duration[][]> {
    return this.http.get<Duration[][]>(this.buildBaseUrl('api/lesson-group-programs/filters'), {
      params: this.params(params),
    });
  }

  retrieveTrainerFilters(params: TrainerFilterParams): Observable<Trainer[][]> {
    return this.http.get<Trainer[][]>(
      this.buildBaseUrl('api/lesson-group-programs/trainer-filters'),
      {
        params: this.params(params),
      }
    );
  }
}
