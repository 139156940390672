import {
  ASSETS_PATH,
  COLOR_TOKENS,
  COMMON_BASE_URL,
  ENVIROMENT,
  PROGRAM_BASE_URL,
  SIZES_TOKEN,
} from '@app/tokens';
import { environment } from '../environments/environment';
import { AppColors } from '../config/colors';
import { AppSizes } from '../config/sizes';
import { AppConfiguration } from '@app/services/configuration/app.configuration';

export const tokenProviders = [
  {
    provide: ENVIROMENT,
    useValue: environment,
  },
  {
    provide: COLOR_TOKENS,
    useValue: new AppColors(),
  },
  {
    provide: SIZES_TOKEN,
    useValue: new AppSizes(),
  },
  {
    provide: ASSETS_PATH,
    useValue: environment.assets,
  },
  {
    provide: COMMON_BASE_URL,
    useFactory: (config: AppConfiguration) => config.baseURL,
    deps: [AppConfiguration],
  },
  {
    provide: PROGRAM_BASE_URL,
    useFactory: (config: AppConfiguration) => config.programURL,
    deps: [AppConfiguration],
  },
];
