import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import dayjs from 'dayjs';
import { Api, IApi } from '../api';
import { fixTZ } from '@app/utils';
import { ProfileParameters, User } from '@app/store/user/models/User';
import {
  PeriodStatistics,
  ProfileStatistics,
  ProfileStatisticsResponse,
} from '@app/store/user/models/ProfileStatistics';
import { Statistics } from '@app/store/user/models/Statistics';
import { COMMON_BASE_URL } from '@app/tokens';

export interface PasswordParameters {
  plainPassword: string;
  plainOldPassword: string;
}

@Injectable({ providedIn: 'root' })
export class UserApiService extends Api implements IApi {
  readonly http = inject(HttpClient);
  readonly baseUrl = inject(COMMON_BASE_URL);

  constructor() {
    super();
  }

  buildBaseUrl(input: string): string {
    return this.baseUrl + '/' + input;
  }

  get(): Observable<User> {
    return this.http.get<User>(this.buildBaseUrl('api/selfuser.json'), {
      params: this.params({
        time_zone: fixTZ(dayjs.tz.guess()),
      }),
    });
  }

  update(data: ProfileParameters | PasswordParameters): Observable<User> {
    return this.http.patch<User>(this.buildBaseUrl('api/selfuser.json'), data);
  }

  statistics(): Observable<ProfileStatistics> {
    return this.http
      .get<ProfileStatisticsResponse>(this.buildBaseUrl('api/selfuser/info.json'))
      .pipe(
        map(statistics => {
          const defaultStats = new ProfileStatistics();

          defaultStats.period_statistics = Object.keys(statistics.period_statistics).reduce(
            (accum: PeriodStatistics, key) => {
              const defaultStats = statistics?.period_statistics?.[key]?.[0];
              if (defaultStats) {
                accum[key] = new Statistics(defaultStats, key);
              }
              return accum;
            },
            {}
          );

          return defaultStats;
        })
      );
  }
}
